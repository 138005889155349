import { checkForNoModels, getImageUrl } from 'utils/helpers';
import { HoldingItemProps } from './types';

export const transformProposalData = (res, isWealthDocument = false) => {
  const advisorDetails = res?.advisorDetails ?? {};
  const clientDetails = res?.clientDetails ?? {};
  const firmDetails = res?.firmDetails ?? {};
  const currentPortfolio = res?.currentPortfolio;
  const recommendationDetails = res?.recommendationDetails;
  const benchmarkDetails = res?.benchmarkPortfolioDetails ?? {};
  const additionalDetails = res?.additionalDetails ?? {};
  const riskDetails = res?.riskDetails ?? {};

  const compareRiskData = {
    accountName: '',
    recommendedPortfolioData: {
      riskDetails: {
        riskCapacity: riskDetails?.riskCapacity ?? 0,
        riskPreference: riskDetails?.riskPreference ?? 0,
      },
      riskScore: recommendationDetails?.riskScore ?? 0,
      lossGain: recommendationDetails?.lossGain ?? {},
    },
    currentPortfolioData: {
      riskDetails: {
        riskCapacity: riskDetails?.riskCapacity ?? 0,
        riskPreference: riskDetails?.riskPreference ?? 0,
      },
      riskScore: currentPortfolio?.riskScore ?? 0,
      lossGain: currentPortfolio?.lossGain ?? {},
    },
    models: [],
  };

  return {
    ...res,
    recommendationDetails:
      recommendationDetails &&
      transformRecommendationsData(
        recommendationDetails ?? {},
        firmDetails?.firmLogo ?? '',
        isWealthDocument,
      ),
    currentPortfolio: currentPortfolio && {
      ...currentPortfolio,
      holdings: transformHoldings(
        currentPortfolio?.holdings ?? [],
        currentPortfolio?.marketValue ?? 0,
        isWealthDocument,
      ),
      fees: isWealthDocument
        ? currentPortfolio?.fees
        : +(currentPortfolio?.fees ?? 0) * 100,
      defaultAccountName: 'Current Portfolio',
    },
    currentPortfolioAllocationsData: {
      assetAllocation:
        Array.isArray(currentPortfolio?.assetAllocation) &&
        currentPortfolio?.assetAllocation.length > 0
          ? constructAssetAllocation(currentPortfolio?.assetAllocation)
          : [],
      regionAllocation:
        Array.isArray(currentPortfolio?.regionAllocation) &&
        currentPortfolio?.regionAllocation.length > 0
          ? constructRegionalAllocation(currentPortfolio?.regionAllocation)
          : [],
      sectorAllocation:
        Array.isArray(currentPortfolio?.sectorAllocation) &&
        currentPortfolio?.sectorAllocation.length > 0
          ? constructSectorAllocation(currentPortfolio?.sectorAllocation)
          : [],
      holdings: transformHoldings(
        currentPortfolio?.holdings ?? [],
        currentPortfolio?.marketValue ?? 0,
        isWealthDocument,
      ),
    },
    compareHoldingsData: {
      modelName: recommendationDetails?.baseModel?.modelName ?? '',
      currentPortfolioHoldings: transformHoldings(
        currentPortfolio?.holdings ?? [],
        currentPortfolio?.marketValue ?? 0,
        isWealthDocument,
      ),
      recommendedPortfolioHoldings: transformHoldings(
        recommendationDetails?.holdings ?? [],
        recommendationDetails?.marketValue ?? 0,
        isWealthDocument,
      ),
    },
    compareRiskData,
    compareRiskDataForRiskFee: isWealthDocument ? { ...compareRiskData } : null,
    advisorDetails: {
      email: advisorDetails?.email ?? '',
      name: `${advisorDetails?.firstName ?? ''} ${advisorDetails?.lastName ?? ''}`,
      firmName: firmDetails?.firmName ?? '',
      firmLogo: firmDetails?.firmLogo ?? '',
    },
    clientDetails: {
      email: clientDetails?.email ?? '',
      name: clientDetails?.firstName + ' ' + clientDetails?.lastName ?? '',
    },
    additionalDetails: {
      duties: additionalDetails?.duties ?? '',
      disclosure: additionalDetails?.disclosure ?? '',
      emailDisclosure: additionalDetails?.emailDisclosure ?? '',
      advisorFeeDisclosure: additionalDetails?.advisorFeeDisclosure ?? '',
    },
    riskStatisticsData: {
      recommendedPortfolio: {
        upsideDownCapture: recommendationDetails?.upsideDownCapture ?? {},
        drawdown: recommendationDetails?.drawdown ?? 0,
        volatility: recommendationDetails?.volatility ?? 0,
      },
      currentPortfolio: {
        upsideDownCapture: currentPortfolio?.upsideDownCapture ?? {},
        drawdown: currentPortfolio?.drawdown ?? 0,
        volatility: currentPortfolio?.volatility ?? 0,
      },
      benchmark: {
        upsideDownCapture: benchmarkDetails?.upsideDownCapture ?? {},
        drawdown: +benchmarkDetails?.drawdown ?? 0,
        volatility: +benchmarkDetails?.volatility ?? 0,
      },
    },
    historicalReturnsSimulationPeriods: {
      tableData: res?.historicalReturnsSimulationPeriods?.table_data ?? [],
      difference:
        res?.historicalReturnsSimulationPeriods?.simulation_difference,
    },
    currentPortfolioForWealth: {
      recommendedPortfolioData: {
        riskBand: {
          riskCapacity: riskDetails?.riskCapacity ?? 0,
          riskPreference: riskDetails?.riskPreference ?? 0,
          riskScore: recommendationDetails?.riskScore ?? 0,
        },
        lossGain: recommendationDetails?.lossGain ?? {},
      },
      currentPortfolioData: {
        riskBand: {
          riskCapacity: riskDetails?.riskCapacity ?? 0,
          riskPreference: riskDetails?.riskPreference ?? 0,
          riskScore: currentPortfolio?.riskScore ?? 0,
        },
        lossGain: currentPortfolio?.lossGain ?? {},
      },
    },
    benchmarkName: benchmarkDetails?.benchmarkName ?? '',
    isWealthDocument: isWealthDocument ?? false,
    baseModelImage:
      checkForNoModels(
        recommendationDetails?.baseModel ?? {},
        firmDetails?.firmLogo ?? '',
      )?.image ?? '',
  };
};

const constructAssetAllocation = (
  assetAllocation: Array<{ assetCategory: string; value: number }>,
) =>
  assetAllocation
    ?.map(allocation => ({
      label: allocation?.assetCategory ?? '',
      data: allocation?.value ?? 0,
    }))
    ?.sort((a, b) => a?.label?.localeCompare(b.label));

const constructRegionalAllocation = (
  reginalAllocation: Array<{
    region: string;
    value: number;
    subRegions: Array<any>;
  }>,
) =>
  reginalAllocation
    .map(allocation => ({
      label: allocation?.region ?? '',
      data: allocation?.value ?? 0,
    }))
    ?.sort((a, b) => a?.label?.localeCompare(b.label));

const constructSectorAllocation = (
  sectorAllocation: Array<{
    sector: string;
    value: number;
    subSectors: Array<any>;
  }>,
) =>
  sectorAllocation.map(allocation => ({
    label: allocation?.sector ?? '',
    data: allocation?.value ?? 0,
  }));
const transformHoldings = (
  holdingsResponse: HoldingItemProps[],
  currentMarketValue = 0,
  isWealthDocument = false,
) => {
  if (holdingsResponse?.length) {
    const getValue = item => {
      if (item?.price && item?.quantity) {
        return item?.price * +item?.quantity;
      }
      if (currentMarketValue) {
        return (item?.weight * 100 * currentMarketValue) / 100;
      }
      return 0;
    };

    return (
      holdingsResponse
        .filter(item => +(item?.quantity ?? item?.weight) !== 0)
        .map(item => ({
          ...item,
          id: item.ticker,
          weight: item?.weight
            ? isWealthDocument
              ? item?.weight
              : +(item.weight * 100).toFixed(2)
            : 0,
          name:
            item?.name === '__UNRECOGNIZED__'
              ? '--'
              : item?.name
                ? item.name
                : '--',
          isUnrecognizedHolding: item.name === '__UNRECOGNIZED__',
          value: item?.value ? item?.value : getValue(item) ?? 0,
        })) ?? []
    );
  }
};

const transformRecommendationsData = (
  data,
  firmLogo = '',
  isWealthDocument = false,
) => {
  if (!data && Object.keys(data).length === 0) return {};

  const assetAllocation = data?.assetAllocation ?? [];
  const reginalAllocation = data?.regionAllocation ?? [];
  const sectorAllocation = data?.sectorAllocation ?? [];
  const baseModel = data?.baseModel ?? {};
  const projectedGrowth = data?.projectedGrowth ?? [];
  const blendedSubModels = data?.subModels ?? [];

  const shapedData = {
    ...data,
    assetAllocation:
      Array.isArray(assetAllocation) && assetAllocation.length > 0
        ? constructAssetAllocation(assetAllocation)
        : [],
    regionAllocation:
      Array.isArray(reginalAllocation) && reginalAllocation.length > 0
        ? constructRegionalAllocation(reginalAllocation)
        : [],
    sectorAllocation:
      Array.isArray(sectorAllocation) && sectorAllocation.length > 0
        ? constructSectorAllocation(sectorAllocation)
        : [],
    baseModel: {
      ...baseModel,
      name: baseModel.modelName ?? '',
    },
    fees: isWealthDocument ? data?.fees : +(data?.fees ?? 0) * 100,
    holdings: transformHoldings(
      data?.holdings ?? [],
      data?.marketValue ?? 0,
      isWealthDocument,
    ),
    projectedGrowth,
    subModels:
      Array.isArray(blendedSubModels) && blendedSubModels.length > 0
        ? blendedSubModels
            ?.map(model => {
              const modelImage = !!model?.logo
                ? getImageUrl(model?.logo)
                : firmLogo;
              return {
                ...model,
                weight: +model?.weight * 100 ?? 0,
                logo: modelImage ?? '',
              };
            })
            .sort((a, b) => b.weight - a.weight)
        : [],
  };

  return shapedData;
};
