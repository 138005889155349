import React, { useContext } from 'react';
import { format } from 'date-fns';
import { Image, Page, Text, View } from '@react-pdf/renderer';
import { PdfViewerContext } from 'app/context';
import sageLogo from 'assets/sageLogo.png';
import { tw } from 'utils/tailwindSetup';
import { isParamContainsSage } from 'utils/helpers';
import { getCoverTitle, trimClientName, trimString } from './utils';

const baseTextStyle = tw(
  'font-inter text-neutral600 text-sm leading-[1.059rem] pt-1.5',
);

function CoverPage({ templateType }: { templateType: string }) {
  const isSageDocument = isParamContainsSage();
  const { advisorDetails: advisorInfo, clientDetails } =
    useContext(PdfViewerContext);
  return (
    <Page
      size={{
        width: 800,
        height: 1130,
      }}
    >
      <View style={tw('relative p-[3.125rem]')}>
        <Image
          style={tw('w-full min-h-[53.125rem]')}
          src={
            'https://storage.googleapis.com/proposal-assets/proposalBackground.png'
          }
        />
        <View
          style={tw(
            'absolute inset-0 flex flex-col justify-center items-center',
          )}
        >
          <View style={tw('w-[90%] m-auto px-[4.0625rem]')}>
            <Text
              style={tw(
                'font-inter font-medium leading-6 text-xl text-neutral500 text-center leading-[1.513rem]',
              )}
            >
              {getCoverTitle(templateType)}
            </Text>
            <Text
              style={tw(
                'font-inter text-4xl font-semibold text-neutral600 text-center capitalize leading-tight pt-1',
              )}
            >
              {trimClientName(clientDetails?.name ?? '', 70)}
            </Text>
          </View>
        </View>
      </View>
      <View style={tw('px-[3.125rem]')}>
        <Text
          style={tw(
            'font-inter capitalize text-default text-base font-semibold leading-[1.21rem]',
          )}
        >
          Prepared by {trimString(advisorInfo?.name ?? '', 20)}
        </Text>
        <View style={tw('flex flex-row justify-between')}>
          <View>
            <Text style={baseTextStyle}>{advisorInfo?.email ?? ''}</Text>
            {!isSageDocument && (
              <Text style={baseTextStyle}>{advisorInfo?.firmName ?? ''}</Text>
            )}
            <Text style={baseTextStyle}>
              {format(new Date(), 'MMMM do, yyyy')}
            </Text>
          </View>
          {!isSageDocument && advisorInfo?.firmLogo && (
            <Image
              src={advisorInfo?.firmLogo}
              style={tw('w-[12.5rem] max-h-[6.25rem] object-contain')}
            />
          )}
        </View>
      </View>
      <View
        style={tw(
          'w-full absolute bottom-[0.83rem] flex flex-row justify-end items-center py-4 px-[3.125rem]',
        )}
      >
        <Text style={tw('font-inter text-neutral600 text-[0.625rem]')}>
          Powered by
        </Text>
        <Image
          src={
            isSageDocument
              ? sageLogo
              : 'https://storage.googleapis.com/proposal-assets/tifinWealthLogo.png'
          }
          style={tw('h-5 object-contain ml-2.5')}
        />
      </View>
    </Page>
  );
}

export default CoverPage;
