import React, { useMemo } from 'react';
import { Document } from '@react-pdf/renderer';

import TableOfContent from '../TableOfContent';
import CoverPage from '../CoverPage';
import IPS from '../IPS';
import RiskBandPage from '../RiskBand';
import CompareAssetAllocation from '../Compare/AssetAllocation';
import CurrentPortfolioHoldings from '../CurrentPortfolio/Holdings';
import { PdfViewerContext } from 'app/context';

import { contentList } from 'utils/constants/pageList';
import { tw } from 'utils/tailwindSetup';
import { isParamContainsSage } from 'utils/helpers';
import { ProposalPdfProps } from './types';
import CurrentPortfolioAllocation from '../CurrentPortfolio/Allocations';
import RecommendationSnapshot from '../RecommendedPortfolio/Snapshot';
import AdvisorFeesDisclosure from '../Disclosures/AdvisorFeesDisclosures';
import RiskDisclosures from '../Disclosures/RiskDisclosure';
import DutiesAndResponsibilities from '../Disclosures/DutiesAndResponsibilities';
import Assessments from '../Assessments';
import ExpenseRatio from '../ExpenseRatio';
import RecommendationAllocation from '../RecommendedPortfolio/Allocations';
import CompareHoldings from '../Compare/Holdings';
import CompareRegionAllocation from '../Compare/RegionAllocation';
import Holdings from '../RecommendedPortfolio/Holdings';
import CompareExpenseRatio from '../Compare/ExpenseRatio';
import RiskScore from '../RecommendedPortfolio/RiskScore';
import AnalyticsPotentialReturn from '../Analytics/PotentialReturn';
import CompareRisk from '../Compare/Risk';
import RiskStatistics from '../Analytics/RiskStatistics';
import GeneralDisclosures from '../Disclosures/General';
import CompareSectorAllocation from '../Compare/SectorAllocation';
import Appendix from '../Disclosures/Appendix';
import AppendixCMA from '../Disclosures/AppendixCMA';
import CompareReturnSimulation from '../Compare/ReturnSimulation';
import HistoricalPerformance from '../HistoricalPerformance';
import HistoricalReturnSimulation from '../Compare/HistoricalReturnSimulation';
import { PAGE_CONTENT_LIST } from 'utils/constants/pageContentList';
import { dataChecks } from 'utils/helpers/dataChecks';
import CurrentPortfolioForWealth from '../CurrentPortfolio/CurrentPortfolioForWealth';
import WealthDisclosure from '../Disclosures/WealthDisclosure';

function ProposalPdf({
  pageList = contentList,
  proposalData,
  editablePageContent,
  templateType,
  modelLogoUrl = '',
  subModelsLogo = [],
}: ProposalPdfProps) {
  const accountName = useMemo(() => {
    return proposalData?.currentPortfolio?.name || 'Current Portfolio';
  }, [proposalData?.currentPortfolio?.name]);

  const isAllAccountsSelected = useMemo(() => {
    return accountName !== 'Current Portfolio';
  }, [accountName]);

  const filteredPageList = useMemo(() => {
    const hideElements = isParamContainsSage();
    if (Array.isArray(pageList) && pageList.length > 0) {
      const typesToHide = hideElements
        ? [
            PAGE_CONTENT_LIST.IPS,
            PAGE_CONTENT_LIST.DUTIES_AND_RESPONSIBILITIES,
            PAGE_CONTENT_LIST.DISCLOSURES,
            PAGE_CONTENT_LIST.ADVISOR_FEE_DISCLOSURE,
            PAGE_CONTENT_LIST.WEALTH_DISCLOSURES,
            PAGE_CONTENT_LIST.RISK_DISCLOSURES,
            PAGE_CONTENT_LIST.APPENDIX,
          ]
        : [];

      const isDataExist = dataChecks(proposalData);
      return pageList
        .map(item => ({
          ...item,
          elements: item?.elements.filter(
            element =>
              !typesToHide.includes(element.type) &&
              (isDataExist?.[element.type] ?? false),
          ),
        }))
        .filter(item => item.elements.length > 0);
    }
  }, [pageList, proposalData]);

  const advisorAndClientDetails = useMemo(() => {
    const advisorName =
      editablePageContent?.coverPage?.advisorDetails?.name ??
      proposalData?.advisorDetails?.name;

    const advisorEmail =
      editablePageContent?.coverPage?.advisorDetails?.email ??
      proposalData?.advisorDetails?.email;

    return {
      advisorDetails: {
        ...(proposalData?.advisorDetails ?? {}),
        name: advisorName ?? '',
        email: advisorEmail ?? '',
      },
      clientDetails: {
        ...(proposalData?.clientDetails ?? {}),
        name:
          editablePageContent?.coverPage?.clientDetails?.name ??
          proposalData?.clientDetails?.name,
      },
    };
  }, [
    editablePageContent,
    proposalData?.advisorDetails,
    proposalData?.clientDetails,
  ]);

  const defaultBaseModel = {
    modelId: '',
    modelName: '',
    modelType: '',
    riskScore: 0,
    seriesName: '',
    sponsorLogo: '',
    sponsorName: '',
  };

  return (
    <PdfViewerContext.Provider value={advisorAndClientDetails}>
      {Array.isArray(filteredPageList) && filteredPageList.length > 0 ? (
        <Document style={tw('bg-white')}>
          {filteredPageList ? (
            filteredPageList
              .flatMap(item => item.elements ?? [])
              .map(page => {
                switch (page?.type) {
                  case PAGE_CONTENT_LIST.COVER:
                    return <CoverPage templateType={templateType ?? ''} />;
                  case PAGE_CONTENT_LIST.TABLE_OF_CONTENTS:
                    return (
                      <TableOfContent
                        pageList={filteredPageList}
                        headerTitle={page?.title ?? ''}
                      />
                    );
                  case PAGE_CONTENT_LIST.IPS:
                    return <IPS headerTitle={page?.title ?? ''} />;
                  case PAGE_CONTENT_LIST.RISK_BAND:
                    return (
                      <RiskBandPage
                        headerTitle={page?.title ?? ''}
                        riskDetails={proposalData?.riskDetails ?? {}}
                      />
                    );
                  case PAGE_CONTENT_LIST.COMPARE_RISK_AND_FEES:
                    return (
                      <CompareRisk
                        compareRiskData={{
                          ...proposalData?.compareRiskData,
                          headerTitle:
                            page?.title || 'Compare - Risk & Expense Ratios',
                        }}
                        showExpenseRatio
                      />
                    );
                  case PAGE_CONTENT_LIST.ASSET_ALLOCATION:
                  case PAGE_CONTENT_LIST.ASSET_ALLOCATION_SNAPSHOT:
                    return (
                      <CompareAssetAllocation
                        compareData={[
                          proposalData?.currentPortfolioAllocationsData
                            ?.assetAllocation ?? [],
                          proposalData?.recommendationDetails
                            ?.assetAllocation ?? [],
                        ]}
                        headerTitle={page?.title ?? ''}
                        isAssetAllocationSnapshotPage={
                          page?.type ===
                          PAGE_CONTENT_LIST.ASSET_ALLOCATION_SNAPSHOT
                        }
                      />
                    );
                  case PAGE_CONTENT_LIST.SECTOR_ALLOCATION:
                    return (
                      <CompareSectorAllocation
                        currentPortfolioAllocationData={
                          proposalData?.currentPortfolioAllocationsData
                            ?.sectorAllocation ?? []
                        }
                        recommendedPortfolioAllocationData={
                          proposalData?.recommendationDetails
                            ?.sectorAllocation ?? []
                        }
                        headerTitle={page?.title ?? ''}
                      />
                    );
                  case PAGE_CONTENT_LIST.REGION_ALLOCATION:
                    return (
                      <CompareRegionAllocation
                        compareData={[
                          proposalData?.currentPortfolioAllocationsData
                            ?.regionAllocation ?? [],
                          proposalData?.recommendationDetails
                            ?.regionAllocation ?? [],
                        ]}
                        headerTitle={page?.title ?? ''}
                      />
                    );
                  case PAGE_CONTENT_LIST.HISTORICAL_PERFORMANCE:
                    return (
                      <HistoricalPerformance
                        headerTitle={page?.title ?? ''}
                        returnsData={{
                          returns: proposalData?.historicalReturns?.returns,
                        }}
                        returnPeriod={
                          proposalData?.historicalReturns?.commonReturns?.period
                        }
                        portfolioInfo={{
                          benchmark: proposalData?.benchmarkName ?? '',
                          recommendedPortfolioName:
                            proposalData?.recommendationDetails?.baseModel
                              ?.modelName ?? '',
                          currentPortfolioName: accountName,
                        }}
                        showAllPortfolioItems
                        isAllAccountsSelected={isAllAccountsSelected}
                      />
                    );
                  case PAGE_CONTENT_LIST.RETURN_SIMULATION:
                    return (
                      <CompareReturnSimulation
                        accountName={accountName}
                        currentPortfolioData={proposalData?.currentPortfolio}
                        recommendedPortfolioData={
                          proposalData?.recommendationDetails
                        }
                        advisorFee={proposalData?.advisorFee}
                        headerTitle={page?.title ?? ''}
                      />
                    );
                  case PAGE_CONTENT_LIST.RECOMMENDED_PORTFOLIO_SNAPSHOT:
                    return (
                      <RecommendationSnapshot
                        recommendationData={{
                          assetAllocation:
                            proposalData?.recommendationDetails
                              ?.assetAllocation ?? [],
                          baseModel:
                            proposalData?.recommendationDetails?.baseModel ??
                            defaultBaseModel,
                          holdings:
                            proposalData?.recommendationDetails?.holdings ?? [],
                          riskBand: proposalData?.riskDetails ?? {},
                        }}
                        headerTitle={page?.title ?? ''}
                        modelLogoUrl={modelLogoUrl ?? ''}
                        riskScore={
                          proposalData?.recommendationDetails?.riskScore ?? 0
                        }
                      />
                    );
                  case PAGE_CONTENT_LIST.RECOMMENDED_PORTFOLIO_HOLDINGS:
                    return (
                      <Holdings
                        recommendationData={{
                          baseModel:
                            proposalData?.recommendationDetails?.baseModel ??
                            defaultBaseModel,
                          holdings:
                            proposalData?.recommendationDetails?.holdings ?? [],
                        }}
                        headerTitle={page?.title ?? ''}
                        modelLogoUrl={modelLogoUrl ?? ''}
                        blendedSubModels={
                          proposalData?.recommendationDetails?.subModels ?? []
                        }
                        subModelsLogo={subModelsLogo ?? []}
                      />
                    );
                  case PAGE_CONTENT_LIST.RECOMMENDED_PORTFOLIO_RISK_SCORE:
                    return (
                      <RiskScore
                        recommendedPortfolioData={{
                          riskBand: {
                            riskCapacity:
                              proposalData?.riskDetails?.riskCapacity ?? 0,
                            riskPreference:
                              proposalData?.riskDetails?.riskPreference ?? 0,
                            riskScore:
                              proposalData?.recommendationDetails?.riskScore ??
                              0,
                          },

                          lossGain:
                            proposalData?.recommendationDetails?.lossGain ?? {},
                        }}
                        portfolioType="RECOMMENDED"
                      />
                    );
                  case PAGE_CONTENT_LIST.RECOMMENDED_PORTFOLIO_EXPENSE_RATIO:
                    return (
                      <ExpenseRatio
                        type="recommendedPortfolio"
                        pageDefaultHeader={page?.title}
                        displayAllPortfolio={false}
                        feesValue={{
                          recommendedPortfolio:
                            proposalData?.recommendationDetails?.fees ?? 0,
                        }}
                        recommendedModelName={
                          proposalData?.recommendationDetails?.baseModel
                            ?.modelName
                        }
                      />
                    );
                  case PAGE_CONTENT_LIST.RECOMMENDED_PORTFOLIO_ALLOCATION_CHARTS:
                    return (
                      <RecommendationAllocation
                        recommendationData={proposalData?.recommendationDetails}
                        headerTitle={page?.title ?? ''}
                        isSnapshotView={templateType === 'IPS_SNAPSHOT'}
                      />
                    );
                  // case 'RECOMMENDED_PORTFOLIO_THEMES':
                  //   return <></>;
                  case PAGE_CONTENT_LIST.CURRENT_PORTFOLIO_HOLDINGS:
                    return (
                      <CurrentPortfolioHoldings
                        headerTitle={page?.title ?? ''}
                        currentPortfolioHoldings={
                          proposalData?.currentPortfolio?.holdings ?? []
                        }
                        accountName={accountName}
                        isAllAccountsSelected={isAllAccountsSelected}
                      />
                    );
                  case PAGE_CONTENT_LIST.CURRENT_PORTFOLIO_ALLOCATION_CHARTS:
                    return (
                      <CurrentPortfolioAllocation
                        currentPortfolioAllocationsData={
                          proposalData?.currentPortfolioAllocationsData ?? {}
                        }
                        headerTitle={page?.title ?? ''}
                      />
                    );
                  case PAGE_CONTENT_LIST.CURRENT_PORTFOLIO:
                    return (
                      <CurrentPortfolioForWealth
                        headerTitle={page?.title ?? ''}
                        currentPortfolioHoldings={
                          proposalData?.currentPortfolio?.holdings ?? []
                        }
                        currentPortfolioAllocationsData={
                          proposalData?.currentPortfolioAllocationsData ?? {}
                        }
                        recommendedPortfolioData={
                          proposalData?.currentPortfolioForWealth
                            ?.recommendedPortfolioData
                        }
                        currentPortfolioData={
                          proposalData?.currentPortfolioForWealth
                            ?.currentPortfolioData
                        }
                        feesValue={proposalData?.currentPortfolio?.fees}
                        recommendedModelName={
                          proposalData?.recommendationDetails?.baseModel
                            ?.modelName ?? ''
                        }
                        isTemplateCurrentPortfolio={
                          templateType === 'CURRENT_PORTFOLIO'
                        }
                      />
                    );
                  case PAGE_CONTENT_LIST.HISTORICAL_RETURN:
                    return (
                      <HistoricalPerformance
                        headerTitle={page?.title ?? ''}
                        returnsData={{
                          returns: proposalData?.historicalReturns?.returns,
                        }}
                        returnPeriod={
                          proposalData?.historicalReturns?.commonReturns?.period
                        }
                        showOnlyCurrentPortfolioAndBenchmark={
                          templateType === 'CURRENT_PORTFOLIO'
                        }
                        portfolioInfo={{
                          benchmark: proposalData?.benchmarkName ?? '',
                          recommendedPortfolioName:
                            proposalData?.recommendationDetails?.baseModel
                              ?.modelName ?? '',
                          currentPortfolioName: accountName,
                        }}
                      />
                    );
                  case PAGE_CONTENT_LIST.POTENTIAL_RETURN:
                    return (
                      <AnalyticsPotentialReturn
                        projectedGrowthData={
                          proposalData?.recommendationDetails
                            ?.projectedGrowth ?? []
                        }
                        headerTitle={page?.title ?? ''}
                        advisorFirmName={
                          proposalData?.advisorDetails?.firmName ?? ''
                        }
                        advisorFee={[
                          {
                            maxAnnualFees: 0,
                            actualFees: proposalData?.advisorFee ?? 0,
                          },
                        ]}
                      />
                    );
                  case PAGE_CONTENT_LIST.POTENTIAL_RISK:
                    return (
                      <RiskStatistics
                        data={proposalData?.riskStatisticsData}
                        headerTitle={page?.title ?? ''}
                        isTemplateCurrentPortfolio={
                          templateType === 'CURRENT_PORTFOLIO'
                        }
                        benchmarkName={proposalData?.benchmarkName ?? ''}
                      />
                    );
                  case PAGE_CONTENT_LIST.COMPARE_HISTORICAL_RETURN_SIMULATION:
                    return (
                      <HistoricalReturnSimulation
                        tableData={
                          proposalData?.historicalReturnsSimulationPeriods
                            ?.tableData
                        }
                        difference={
                          proposalData?.historicalReturnsSimulationPeriods
                            ?.difference
                        }
                        headerTitle={page?.title ?? ''}
                        benchmark={proposalData?.benchmarkName ?? ''}
                      />
                    );
                  case PAGE_CONTENT_LIST.COMPARE_RISK:
                    return (
                      <CompareRisk
                        compareRiskData={
                          {
                            ...proposalData?.compareRiskData,
                            headerTitle: page?.title,
                            accountName: accountName,
                          } ?? {}
                        }
                      />
                    );
                  case PAGE_CONTENT_LIST.COMPARE_EXPENSE_RATIO:
                    return (
                      <CompareExpenseRatio
                        currentPortfolioFeesValue={
                          proposalData?.currentPortfolio?.fees
                        }
                        recommendedPortfolioFeesValue={
                          proposalData?.recommendationDetails?.fees
                        }
                        headerTitle={page?.title ?? ''}
                        accountName={accountName}
                      />
                    );
                  case PAGE_CONTENT_LIST.COMPARE_HOLDINGS:
                    return (
                      <CompareHoldings
                        compareHoldingsData={proposalData?.compareHoldingsData}
                        accountName={accountName}
                        headerTitle={page?.title ?? ''}
                      />
                    );

                  case PAGE_CONTENT_LIST.ASSESSMENT_INPUTS:
                    return (
                      <Assessments
                        assessmentsData={proposalData?.assessmentSubmissions}
                      />
                    );
                  case PAGE_CONTENT_LIST.DUTIES_AND_RESPONSIBILITIES:
                    return (
                      <DutiesAndResponsibilities
                        dutiesAndResponsibilities={
                          (editablePageContent?.editedDutiesText
                            ?.dutiesAndResponsibilities ||
                            proposalData?.additionalDetails?.duties) ??
                          ''
                        }
                        headerTitle={page?.title ?? ''}
                      />
                    );
                  case PAGE_CONTENT_LIST.DISCLOSURES:
                    return (
                      <GeneralDisclosures
                        disclosures={
                          editablePageContent?.editedDutiesText?.disclosure ||
                          proposalData?.additionalDetails?.disclosure
                        }
                        headerTitle={page?.title ?? ''}
                      />
                    );
                  case PAGE_CONTENT_LIST.ADVISOR_FEE_DISCLOSURE:
                    return (
                      <AdvisorFeesDisclosure
                        advisorFees={proposalData?.advisorFee ?? 0}
                        advisorFeesDisclosure={
                          (editablePageContent?.editedDutiesText
                            ?.advisorFeeDisclosure ||
                            proposalData?.additionalDetails
                              ?.advisorFeeDisclosure) ??
                          ''
                        }
                        headerTitle={page?.title ?? ''}
                      />
                    );
                  case PAGE_CONTENT_LIST.WEALTH_DISCLOSURES:
                    return <WealthDisclosure headerTitle={page?.title ?? ''} />;
                  case PAGE_CONTENT_LIST.RISK_DISCLOSURES:
                    return <RiskDisclosures headerTitle={page?.title ?? ''} />;
                  case PAGE_CONTENT_LIST.APPENDIX:
                    return <Appendix headerTitle={page?.title ?? ''} />;
                  case PAGE_CONTENT_LIST.APPENDIX_CMA:
                    return <AppendixCMA headerTitle={page?.title ?? ''} />;
                  default:
                    return <></>;
                }
              })
          ) : (
            <></>
          )}
        </Document>
      ) : (
        <></>
      )}
    </PdfViewerContext.Provider>
  );
}

export default ProposalPdf;
