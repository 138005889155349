import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { Font, pdf } from '@react-pdf/renderer';
import { RenderDownloadProps, getFilePlugin } from '@react-pdf-viewer/get-file';
import '@react-pdf-viewer/print/lib/styles/index.css';
import { useQuery } from 'react-query';
import Loader from 'app/components/Loader';

import downloadProposalIcon from 'assets/downloadProposal.png';

import ProposalPdf from 'app/pages/ProposalPdf';
import { getQueryParams } from 'utils/helpers';
import { fetchProposalData } from 'utils/api/fetchProposalData';
import { transformProposalData } from 'utils/transformers';
import { fetchSvg } from 'utils/api/fetchSvg';
import './index.css';
import { ProposalProps } from 'app/pages/ProposalPdf/types';
import { fetchSubModelLogos } from 'utils/api/fetchSubModelLogos';

Font.register({
  format: 'truetype',
  family: 'Inter',
  fonts: [
    {
      src: 'https://storage.googleapis.com/proposal-assets/Inter-Regular.ttf',
      fontWeight: 400,
    },
    {
      src: 'https://storage.googleapis.com/proposal-assets/Inter-Medium.ttf',
      fontWeight: 500,
    },
    {
      src: 'https://storage.googleapis.com/proposal-assets/Inter-SemiBold.ttf',
      fontWeight: 600,
    },
    {
      src: 'https://storage.googleapis.com/proposal-assets/Inter-Bold.ttf',
      fontWeight: 700,
    },
    {
      src: 'https://storage.googleapis.com/proposal-assets/Inter-Italic.ttf',
      fontWeight: 400,
      fontStyle: 'italic',
    },
  ],
});

const App = ({
  wealthProposalId = '',
  pageList,
  editablePageContent,
  templateType,
  updateLoadingStateForPdfApp,
}: ProposalProps) => {
  const params = getQueryParams();
  const [proposalId, setProposalId] = useState<string>('');
  const [debouncedLoader, setDebouncedLoader] = useState(false);
  const downloadProposalRef = useRef<HTMLButtonElement>(null);

  const getFilePluginInstance = getFilePlugin({
    fileNameGenerator: () => {
      return 'Investment Proposal';
    },
  });
  const { Download } = getFilePluginInstance;

  useEffect(() => {
    const proposalId = params.get('proposal_id');
    if (proposalId && !wealthProposalId) setProposalId(proposalId);
  }, [params, wealthProposalId]);

  const isWealthDocument = useMemo(() => {
    return !!wealthProposalId;
  }, [wealthProposalId]);

  const { data, isLoading, isError } = useQuery(
    ['proposal-data', proposalId, wealthProposalId],
    () => fetchProposalData(wealthProposalId || proposalId),
    {
      select: res => transformProposalData(res, isWealthDocument),
      enabled: !!wealthProposalId || !!proposalId,
    },
  );

  const { data: modelLogoUrl, isLoading: modelLoader } = useQuery(
    ['model-logo', data],
    () => fetchSvg(data?.baseModelImage ?? ''),
    {
      enabled: !!wealthProposalId && !!data,
    },
  );

  const { data: subModelsLogo } = useQuery(
    ['sub-models-logo', data],
    () => fetchSubModelLogos(data?.recommendationDetails ?? {}),
    {
      enabled:
        !!wealthProposalId &&
        !!data &&
        !!data?.recommendationDetails?.subModels?.length,
    },
  );

  useEffect(() => {
    const handleDownloadProposal = () => {
      if (downloadProposalRef?.current) {
        downloadProposalRef.current.click();
      }
    };

    document.addEventListener('download', handleDownloadProposal);

    return () => {
      document.removeEventListener('download', handleDownloadProposal);
    };
  }, []);

  const fetchPdfBlob = async () => {
    const blobPdf = await pdf(
      <ProposalPdf
        proposalData={data}
        pageList={pageList}
        editablePageContent={editablePageContent}
        templateType={templateType}
        modelLogoUrl={modelLogoUrl ?? ''}
        subModelsLogo={subModelsLogo ?? []}
      />,
    ).toBlob();
    return URL.createObjectURL(blobPdf);
  };

  const { data: blobPdf, isLoading: blobLoader } = useQuery(
    [
      'blob-data',
      pageList,
      data,
      editablePageContent,
      modelLogoUrl,
      subModelsLogo,
    ],
    fetchPdfBlob,
    {
      enabled:
        !!data &&
        !isLoading &&
        !!(wealthProposalId || proposalId) &&
        !modelLoader,
    },
  );

  useEffect(() => {
    const allElements =
      Array.isArray(pageList) && pageList?.length > 0
        ? pageList.flatMap(page => page?.elements ?? [])
        : [];

    const shouldUpdateLoadingState =
      allElements.length === 0 ? true : !!blobPdf;

    if (updateLoadingStateForPdfApp) {
      updateLoadingStateForPdfApp(shouldUpdateLoadingState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blobPdf, pageList]);

  const loader = useMemo(() => {
    return isLoading || blobLoader;
  }, [blobLoader, isLoading]);

  useEffect(() => {
    if (loader) {
      setDebouncedLoader(true);
    } else {
      const handler = setTimeout(() => {
        setDebouncedLoader(false);
      }, 300);

      return () => {
        clearTimeout(handler);
      };
    }
  }, [loader]);

  if (debouncedLoader) return <Loader />;
  if (isError) return <p>Something went wrong</p>;

  if (!proposalId && !wealthProposalId)
    return <h1>Proposal ID not provided</h1>;

  return (
    <div className={wealthProposalId ? 'wealth-pdf-viewer' : 'pdf-viewer'}>
      {blobPdf && (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js">
          <Viewer
            fileUrl={blobPdf}
            enableSmoothScroll
            defaultScale={1}
            plugins={[getFilePluginInstance]}
          />
          <Download>
            {(props: RenderDownloadProps) => (
              <div className="download-proposal-btn-container">
                <button
                  className="download-proposal-btn"
                  onClick={props.onClick}
                  ref={downloadProposalRef}
                  style={{
                    display: wealthProposalId ? 'none' : 'flex',
                  }}
                >
                  <img
                    src={downloadProposalIcon}
                    className="download-proposal-icon"
                    alt="download-proposal-btn-icon"
                  />
                  <p className="download-proposal-cta">Download Proposal</p>
                </button>
              </div>
            )}
          </Download>
        </Worker>
      )}
    </div>
  );
};

export default App;
